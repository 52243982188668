html {
  scroll-behavior: smooth;
}

.Home {
  text-align: center;
  background-image: url('./res/img/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: rgba(49,142,223,.95);
  background: linear-gradient(145deg,#4b6cb7,#182848);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App{
  background-color: #eee;
}

.features-bg{
  background-color: #eee;
}

.features-wrapper {
    background-color: #fff;
    width: 95%;
    margin: 0 auto;
    margin-top: 3%;
    border-radius: 10px;
    padding: 5vh;
}

.progressBar{
  background-color: #26a69a;
  /* background: linear-gradient(145deg,#1a756d, #000); */
  width: 90%;
  height: 100%;
  padding: 1px;
}

.progressBarPercentage {
    margin-top: -30px;
    float: right;
    margin-right: 5px;
}

.skillItem{
  display: flex;
  height: 40px;
}
.skillItem .skillName{
  color: white;
  background: #1a756d;
  padding: 0 10px;
  min-width: 200px !important;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: calc(100% + 0.1vw);
}

.footer {
    text-align: center;
    color: black !important;
    height: 10px;
    padding-bottom: 1%;
}

.footer a{
  color: black !important;
  text-decoration: underline;
}




.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ScrollDown Arrow */
.arrow{
    position: absolute;
    left: 50%;
    bottom: 5%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.arrow span{
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #06A8FF;
    border-right: 5px solid #06A8FF;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}


h1{
  font-size: 190%;
}

h5{
  color: #4ea199;
}

ul:not(.browser-default)>li {
    list-style-type: disc;
    margin-left: 5%;
}